.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  z-index: 1;
}

.popup_open {
  visibility: visible;
}

.popup__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  padding: 30px 20px;
  box-sizing: border-box;
  background-color: #fbeec1;
  border-radius: 10px;
  box-shadow: 0 0 25px rgba(0, 0, 0, .15);
  position: relative;
}

.popup__text {
  margin: 0 0 20px;
  padding: 0;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #7f3c58;
}

.popup__buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.popup__button {
  width: 100px;
  height: 45px;
  border: none;
  border-radius: 3px;
  font-size: 14px;
  line-height: 16.94px;
  font-weight: 500;
  cursor: pointer;
}

.popup__button:hover {
  opacity: 0.8;
}

.popup__button_type_confirm {
  background-color: #8d8741;
  color: #fbeec1;

}

.popup__button_type_reject {
  background-color: #daad86;
  color: #7f3c58;

}


@media screen and (max-width: 670px) {
  .popup__container {
    width: 280px;
    padding: 20px 20px 20px;
  }
}