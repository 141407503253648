.not-found {
  width: 100%;
  margin: 0;
  padding: 20px calc((100vw - 1140px) / 2);
  min-height: calc(100dvh - 150px);
  background-color: #659dbd;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.not-found__title {
  color: #fbeec1;
  margin: 20px 0 40px;
  padding: 0;
  font-weight: 600;
  font-size: 32px;
  text-shadow: .5px .5px 1px #000;
  text-align: center;
}

.not-found__text {
  color: #fbeec1;
  margin: 20px 0;
  padding: 0;
  font-weight: 200;
  font-size: 16px;
  text-align: center;
}

.not-found__button {
  width: 200px;
  height: 45px;
  background-color: #8d8741;
  border: none;
  border-radius: 3px;
  color: #fbeec1;
  font-size: 14px;
  line-height: 16.94px;
  font-weight: 500;
  cursor: pointer;
}

.not-found__button:hover {
  opacity: 0.8;

}