.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  border-bottom: 1px solid #fbeec1;
  margin-bottom: 22px;
  gap: 10px;
  padding-bottom: 10px;
}

.item_type_task {
  padding-bottom: 5px;
  margin-bottom: 17px;
}

.item_type_form {
  margin-bottom: 0;
}



.item_type_reorder {
  margin-bottom: 16px;
}


.item__buttons {
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 20px;
}

.item__name {
  margin: 0;
  padding: 0;
  color: #fbeec1;
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;

}

.item__name_type_zone {
  font-weight: 300;
  font-size: 18px;
}

.item__name_type_task {
  font-weight: 200;
  font-size: 16px;
}

.item__name_checked {
  color: #8d8741
}

.item__form {
  margin-bottom: 0px;
}

.item__input {
  background-color: transparent;
  border: none;
  border-radius: 8px;
  width: 100%;
}

.item__input:focus {
  outline: none;

}

.item__input-error {
  padding-bottom: 0;
  font-size: 10px;
  min-height: 12px;
}

.item__button {
  width: 20px;
  height: 20px;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  border: none;
}

.item__button:hover {
  opacity: 0.8;
}

.item__button:disabled {
  cursor: default;
}

.item__button:disabled.item__button:hover {
  opacity: 1;
}


.item__button_hidden {
  display: none;

}

.item__button_type_delete {
  background-image: url(../images/delete-button.svg);
}

.item__button_type_edit {
  background-image: url(../images/edit-button.svg);
}

.item__button_type_reorder {
  background-image: url(../images/reorder-button.svg);
  transform: rotate(90deg);

}

.item__button_type_reorder:disabled {
  background-image: url(../images/reorder-button-disabled.svg);
}

.item__button_type_save {
  background-image: url(../images/save-button.svg);
}

.item__button_type_save:disabled {
  background-image: url(../images/save-button-disabled.svg);
}

.item__button_type_discard {
  background-image: url(../images/discard-button.svg);
}

.item__button_type_open {
  background-image: url(../images/collapse-button.svg);
  transform: rotate(180deg);

}

.item__button_type_collapse {
  background-image: url(../images/collapse-button.svg);

}

.item__button_type_add {
  background-image: url(../images/add-button.svg);

}

.item__button_type_check {
  background-image: url(../images/check-button.svg);
}

.item__button_type_refresh {
  background-image: url(../images/refresh-button.svg);
}

.item__select {
  height: 28px;
  border: none;
  border-radius: 4px;
  background-color: #fbeec1;
  color: #8d8741;

}