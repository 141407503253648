.button {
  width: 100%;
  height: 45px;
  background-color: #8d8741;
  border: none;
  border-radius: 3px;
  color: #fbeec1;
  font-size: 14px;
  line-height: 16.94px;
  font-weight: 500;
  cursor: pointer;

}

.button:enabled.button:hover {
  opacity: 0.8;
}

.button:disabled {
  cursor: default;
  background-color: #daad86;
  color: #7f3c58;
}

.button_hidden {
  display: none;
}


@media screen and (max-width: 426px) {
  .button {
    font-size: 12px;
    line-height: 14.52px;
  }
}
