.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding: 20px calc((100vw - 1140px) / 2);
    width: 100%;
    height: 75px;
    background-color: #bc986a;
    box-sizing: border-box;    
  }

  .footer__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0;
    padding: 0;
  }

  .footer__list-item {
    margin: 0;
    padding: 0;
    list-style-type: none;
    color: #fbeec1;
    font-weight: 200;
    font-size: 12px;
  }

  .footer__link {
    color: #fbeec1;
    cursor: pointer;
  }

  .footer__link:hover {
    opacity: 0.8;
    }  

@media screen and (max-width: 1280px) {
    .footer {
        padding-left: 70px;
        padding-right: 70px;
      }
    }

@media screen and (max-width: 768px) {
    .footer {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media screen and (max-width: 600px) {
    .footer {
        padding-top: 8px;
        padding-bottom: 10px;
        flex-direction: column;
        justify-content: start;
        gap: 4px
    }

    .footer__list {
        gap: 4px;
    }
    .footer__list-item {
        font-size: 10px;
    }
}