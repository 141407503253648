.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0 calc((100vw - 1140px) / 2);
  width: 100%;
  height: 75px;
  background-color: #bc986a;
  box-sizing: border-box;
  position: relative;
}

.header__logo {
  width: 60px;
  height: 60px;
  background-image: url(../../images/logo.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #bc986a;
  cursor: pointer;
  border: none;
  margin-right: 374px;
  padding: 0;
  display: block;
}

.header__logo:hover {
  opacity: 0.8;
}

.header__menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 706px;
}

.header__overlay {
  width: calc(100vw - 220px);
  height: 100dvh;
  position: absolute;
  top: 74px;
  left: 0px;
  background-color: rgba(32, 32, 32, 0.3);

}

.header__nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin: 0;
  padding: 0;
}

.header__link {
  color: #fbeec1;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  text-shadow: .5px .5px 1px #000;
  background-color: transparent;
  border: none;
}

.header__link:hover {
  opacity: 0.8;
}

.header__link_active {
  font-weight: 800;
}

.header__link_active:hover {
  opacity: 1;
}

.header__item {
  list-style-type: none;
}

.header__button {
  width: 60px;
  height: 60px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  cursor: pointer;
  border: none;
  padding: 0;
  display: none;
}

.header__button_type_burger {
  background-image: url(../../images/burger-menu.svg);
}

.header__button_type_close {
  background-image: url(../../images/close-button.svg);
}

@media screen and (max-width: 1280px) {
  .header {
    padding-left: 70px;
    padding-right: 70px;
  }

  .header__logo {
    margin-right: calc((100vw - 531px) / 2);
  }

  .header__menu {
    width: calc(100% - 60px - ((100vw - 531px)/2))
  }
}



@media screen and (max-width: 768px) {
  .header {
    padding: 15px 30px;
  }

  .header__button {
    display: block;
  }

  .header__menu {
    position: absolute;
    top: 74px;
    right: 0px;
    height: 100dvh;
    flex-direction: column;
    justify-content: flex-start;
    width: 220px;
    gap: 40px;
    padding-top: 25px;
    background-color: #bc986a;
    display: none;
    z-index: 2;
  }

  .header__menu_opened {
    display: flex;
  }

  .header__nav {
    flex-direction: column;
  }

}

@media screen and (max-width: 426px) {
  .header {
    padding: 18px 14px;
  }
}