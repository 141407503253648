@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(./fonts/Inter-Regular.woff) format('woff')
    }

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(./fonts/Inter-Medium.woff) format('woff')
    }

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(./fonts/Inter-Black.woff) format('woff')
}

@font-face {
    font-family: 'Florida Vibes';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url(./fonts/Florida-Vibes.otf) format('otf')
}
