.profile {
  width: 100%;
  margin: 0;
  padding: 20px calc((100vw - 1140px) / 2);
  min-height: calc(100dvh - 150px);
  background-color: #659dbd;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile__container {
  display: flex;
  flex-direction: column;
  width: 400px;
  align-items: center;
  justify-items: flex-start;
}

.profile__label {
  box-sizing: border-box;
  border-bottom: 1px solid #fbeec1;
  padding: 17px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
margin-bottom: 8px;
}

.profile__label_inedited {
  margin-bottom: 20px;
}

.profile__input {
  background-color: transparent;
  max-width: 200px;
  height: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #fbeec1;
  text-align: right;
  border: none;
  border-radius: 4px
}

.profile__input_inedited {
  height: 22px;
} 

.profile__input:focus {
  outline: 1px solid #fbeec1;  
}

.profile__input-error {
  padding-bottom: 0;
}

.profile__options {
  margin-top: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.profile__option {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #fbeec1;
  cursor: pointer;
}

.profile__option:hover {
  opacity: 0.7;
}

.profile__option_type_exit {
  color: #7f3c58;
  font-weight: 500;
}

.profile__api-error {
  margin: 70px 0 20px;
}


