.zone {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 0 0 10px;
    gap: 10px;
}

.zone__tasks {
    list-style-type: none;
    margin: 0 0 22px 30px;
    padding: 0;
}

@media screen and (max-width: 426px) {
    .zone__tasks {
        margin-left: 0;
    }
}